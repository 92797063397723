// ============================
//     header css start
// ============================

.pc-header {
    background: $header-background;
    box-shadow: 0 1px 0 0 $border-color;
    color: $header-color;
    min-height: $header-height;
    position: fixed;
    left: $sidebar-width;
    right: 0;
    z-index: 1025;

    ul {
        margin-bottom: 0;
        display: inline-flex;
    }

    .header-wrapper {
        display: flex;
        padding: 0 30px;
    }

    .pc-h-item {
        min-height: $header-height;
        display: flex;
        align-items: center;
        position: relative;
    }

    .pc-head-link {
        color: $header-color;
        display: inline-flex;
        align-items: center;
        padding: 0.60rem 0.9rem;
        margin: 0 5px;
        border-radius: 4px;
        position: relative;
        font-weight: 500;

        > i {
            font-size: 20px;
            color: $primary;

            &.material-icons-two-tone {
                font-size: 24px;
                background-color: $header-color;
            }
        }

        > svg {
            width: 20px;
            height: 20px;
        }

        &.active,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
            color: $primary;
            background: shift-color($primary, $soft-bg-level);

            .hamburger {
                .hamburger-inner {
                    background-color: $primary;

                    &::after,
                    &::before {
                        background-color: $primary;
                    }
                }
            }

            i.material-icons-two-tone {
                background-color: $primary;
            }
        }

        .pc-h-badge {
            position: absolute;
            top: 3px;
            right: 10px;
            border-radius: 50%;
            font-size: 9px;

            &.dots {
                width: 9px;
                height: 9px;
                top: 7px;
                right: 16px;
                padding: 0;
            }
        }

        .user-desc,
        .user-name {
            display: block;
            line-height: 1;
        }

        .user-name {
            margin-bottom: 5px;
            font: {
                size: 15px;
                weight: 600;
            }
        }

        .user-desc {
            font: {
                size: 12px;
                weight: 400;
            }
            color: transparentize($header-color, 0.3);
        }
    }

    .pc-h-dropdown {
        .fa-circle {
            font-size: 5px;
            vertical-align: middle;
        }
        transform: none !important;
        top: 100% !important;

        &.dropdown-menu-end {
            right: 0 !important;
            left: auto !important;
        }
    }

    .pc-level-menu {
        position: relative;

        &:hover {
            > .dropdown-menu {
                display: block;
                left: 100%;
                top: -18px !important;
            }
        }
    }

    .pc-mega-menu {
        position: static;

        .pc-mega-dmenu {
            transform: none !important;
            left: 0 !important;
            right: 0 !important;
            top: 100% !important;
            padding: 0;

            .row.g-0 {
                .col {
                    padding: 15px 0;
                    border-right: 1px dashed $border-color;
                }
            }

            .mega-title {
                margin: 10px 25px;
                position: relative;
            }

            .pc-mega-list {
                display: block;
                list-style: none;
                padding-left: 0;
            }
        }
    }

    .drp-search {
        min-width: 20rem;
    }
    #vertical-nav-toggle{
        margin-left: -15px;
    }
    .pc-cart-menu .drp-cart {
        min-width: 20rem;
        padding: 0;

        .cart-head {
            border-radius: 2px 2px 0 0;
            padding: 20px;
            background: transparentize(shift-color($primary, $soft-bg-level),0.8);
            border-bottom: 1px solid $border-color;
        }

        .cart-item {
            display: flex;
            padding: 10px 20px;

            +.cart-item {
                border-top: 1px dashed $border-color;
            }

            img {
                width: 60px;
            }

            .cart-desc {
                flex: 1;
            }

            .p-star {
                fill: currentColor;
            }

            &.table-responsive {
                border-top: 1px solid $border-color;
                background: transparentize(shift-color($primary, $soft-bg-level),0.8);
            }

            svg {
                width: 14px;
                height: 14px;
            }
        }
    }

    .user-avtar {
        width: 40px;
        margin-right: 10px;
        border-radius: 50%;
    }
}

.minimenu {
    .pc-header {
        left: $sidebar-collapsed-width;
    }
}

.pc-mob-header {
    display: none;
    background: $brand-color;
    height: 70px;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 1026;
    left: 0;
    right: 0;
    padding: 0 10px 0 30px;

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
        background: #fff;
    }

    .pc-head-link,
    a {
        color: #fff;
    }
}

.notification-modal.modal.fade .modal-dialog {
    transform: translate(100%, 0);
}

.notification-modal.modal.show .modal-dialog {
    transform: none;
}

.notification-modal {
    padding-right: 0 !important;

    .modal-dialog {
        margin: 0 0 0 auto;
    }

    .modal-content {
        min-height: 100vh;
        border: none;
        border-radius: 0;
        box-shadow: -1px 0 9px -3px rgba(0, 0, 0, 0.6);
    }
}
@media (max-width: 1024px) {
    .pc-mob-header {
        display: flex;
    }

    .pc-header {
        top: 0;
        left: 0;
        transition: all 0.15s ease-in-out;

        .user-avtar {
            margin-right: 0;
        }

        .pc-head-link {
            .user-desc,
            .user-name {
                display: none;
            }

            &#mobile-collapse {
                position: absolute;
                left: 10px;
            }
        }

        .pcm-logo {
            margin-left: 30px;

            img {
                max-width: 160px;
            }
        }

        &.mob-header-active {
            top: $header-height;

            .header-wrapper {
                background: $header-background;
                position: relative;
                z-index: 5;
            }
        }

        .pc-md-overlay {
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: 1;
            background: rgba(0,0,0,0.2);
        }

        .pc-level-menu .dropdown-menu {
            left: 0;
            display: block;
            padding-left: 30px;
        }

        .pc-mega-menu .pc-mega-dmenu .row.g-0 > .col {
            flex: 100%;
        }

        .pc-mob-drp {
            transition: left 0.15s ease-in-out;
            left: -#{$sidebar-width};
            position: fixed;
            top: $header-height;
            bottom: 0;

            &.mob-drp-active {
                left: 0;
                z-index: 1026;
                background: $header-background;
                box-shadow: $header-shadow;
                width: $sidebar-width;
                overflow-y: auto;
                padding: 30px 0;

                .pc-h-item {
                    display: block;
                    min-height: auto;
                    position: relative;

                    .pc-head-link {
                        display: block;
                        margin: 5px 10px !important;
                    }

                    .dropdown-menu {
                        position: relative !important;
                        width: 100%;
                        float: none;
                        box-shadow: none;
                    }
                }

                ul {
                    display: block;
                }
            }
        }
    }

    .minimenu {
        .pc-header {
            left: 0;
        }
    }
}

#pc-noti-home {
    > .media {
        &:hover {
            margin: -15px;
            padding: 15px;
            background: rgba($primary, 0.1);
        }
    }
}
@include media-breakpoint-down(sm) {
    .pc-header {
        .pc-head-link {
            padding: 0.65rem;
            margin: 0;
        }

        .pc-h-item {
            position: static;

            .pc-h-dropdown {
                left: 0 !important;
                right: 0 !important;
            }
        }
    }
}
// header css end
